import React, { Children } from 'react';
import PropTypes from 'prop-types';
import useEnvironmentalizeLink from '@lmig/dotcom-aspect-hooks/useEnvironmentalizeLink';
import getConfig from "next/config";
import useLinkAppend from '@lmig/dotcom-aspect-hooks/useLinkAppend';

const {
  publicRuntimeConfig: { useSsl },
} = getConfig();

/**
 * Allows a link to be environmentalized by application and the environment
 * the application is deployed to.
 *
 * @see https://aspect-playbook-development.us-east-1.np.paas.lmig.com/#/component-api/components/LinkEnvironmentalize
 *
 * @example
 * // <a href="https://dev-www2.libertymutual.com/auto">Auto Page</a>
 * <LinkEnvironmentalize domain="LM_COM">
 *   <a href="/auto">Auto Page</a>
 * </LinkEnvironmentalize>
 *
 */
const LinkEnvironmentalize = ({
  link = '',
  children = undefined,
  domain = undefined,
  analyticsAction = 'action url',
  attribution = false,
}) => {
  if (typeof children === 'string') {
    children = <a>{children}</a>; // eslint-disable-line no-param-reassign
  }

  let appendParams = s => s;

  try {
    const funcs = useLinkAppend();
    appendParams = funcs.appendParams; // eslint-disable-line prefer-destructuring
  } catch (e) {
    if (attribution) {
      throw new Error(
        "You're trying to use <LinkEvironmentalize> with attribution outside of <ParameterContextProvider>",
      );
    }
  }

  const child = Children.only(children);

  const linkHref = React.isValidElement(child) && child.props.href ? child.props.href : link;
  const href = attribution ? appendParams(linkHref) : linkHref;
  const environmentalizedLink = useEnvironmentalizeLink({ link: href, domain, ssl: useSsl });
  const url = new URL(environmentalizedLink);

  const linkProps = {
    'data-action': analyticsAction || undefined,
    ...child.props,
    href: url.toString(),
  };

  return React.cloneElement(child, linkProps);
};

LinkEnvironmentalize.propTypes = {
  /** Link being environmentalized. ex: /auto */
  link: PropTypes.string,
  children: PropTypes.node,
  /** Token to get correct domain */
  domain: PropTypes.oneOf([
    '',
    'COMPARION',
    'FEEDBACK',
    'GOOGLE_SEARCH',
    'IMAGE',
    'LM',
    'LMG',
    'LMG_JOBS',
    'LM_COM'
  ]),
  /** Analytics tag */
  analyticsAction: PropTypes.string,
  attribution: PropTypes.bool,
};

export default LinkEnvironmentalize;
